<template>
    <div class="Resourcesfigure">
        <div class="Resource clearfix">
            <div class="Resource_info">
                <div class="demo_suff clearfix">
                    <span class="_suff_span fl">脑图：</span>
                    <div class="_suff_div fr">
                        <el-input
                            v-model="input" size="mini">
                        </el-input>
                    </div>
                </div>
                <div class="demo_suff clearfix">
                    <span class="_suff_span fl">院系：</span>
                    <div class="_suff_div fr">
                        <el-input
                            v-model="input" size="mini">
                        </el-input>
                    </div>
                </div>
                <div class="demo_suff clearfix">
                    <span class="_suff_span fl">姓名：</span>
                    <div class="_suff_div fr">
                        <el-input
                            v-model="input" size="mini">
                        </el-input>
                    </div>
                </div>
                
            </div>
            <div class="Resource_left fl">
                <div class="_left_k">
                    <div class="_left_k_one">
                        <p class="_left_k_title">
                            <span>关键能力</span>
                            <img src="./../../../assets/img/lan_nail.png" alt="">
                        </p>
                        <div class="_left_k_content">
                            <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Resource_center fl">
                <div class="cen_top">
                    <ul class="cen_top_ul clearfix">
                        <li class="fl">
                            <p class="_top_ul_title">
                                谁的问题
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                过往业绩
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                打破方式
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                生态位
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                支撑资源
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="cen_middle clearfix">
                    <div class="_middle_one fl">
                        <img src="./../../../assets/img/right.png" alt="">
                    </div>
                    <div class="_middle_two fl">
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/top.png" alt="">
                        </div>
                        <div class="_m_two_s">
                            <span>目标问题</span>
                        </div>
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/bott.png" alt="">
                        </div>
                    </div>
                    <div class="_middle_one fl">
                        <img src="./../../../assets/img/right.png" alt="">
                    </div>
                    <div class="_middle_two fl">
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/top.png" alt="">
                        </div>
                        <div class="_m_two_s">
                            <span>类似企业</span>
                        </div>
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/bott.png" alt="">
                        </div>
                    </div>
                    <div class="_middle_one fl">
                        <img src="./../../../assets/img/right.png" alt="">
                    </div>
                    <div class="_middle_two fl">
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/top.png" alt="">
                        </div>
                        <div class="_m_two_s">
                            <span>创新差异</span>
                        </div>
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/bott.png" alt="">
                        </div>
                    </div>
                    <div class="_middle_one fl">
                        <img src="./../../../assets/img/right.png" alt="">
                    </div>
                    <div class="_middle_two fl">
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/top.png" alt="">
                        </div>
                        <div class="_m_two_s">
                            <span>假设市场</span>
                        </div>
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/bott.png" alt="">
                        </div>
                    </div>
                    <div class="_middle_one fl">
                        <img src="./../../../assets/img/right.png" alt="">
                    </div>
                    <div class="_middle_two fl">
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/top.png" alt="">
                        </div>
                        <div class="_m_two_s">
                            <span>预设业务</span>
                        </div>
                        <div class="_m_two_t">
                            <img src="./../../../assets/img/bott.png" alt="">
                        </div>
                    </div>
                    <div class="_middle_one fl">
                        <img src="./../../../assets/img/right.png" alt="">
                    </div>
                </div>
                <div class="cen_bottom">
                    <ul class="cen_top_ul clearfix">
                        <li class="fl">
                            <p class="_top_ul_title">
                                过往方案
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                主营业务
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                支撑资源
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                切入点
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                        <li class="fl">
                            <p class="_top_ul_title">
                                产品形态
                            </p>
                            <div class="_top_ul_input">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                                </el-input>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="Resource_right fl">
                <div class="_left_k">
                    <div class="_left_k_one">
                        <p class="_left_k_title">
                            <span>价值主张</span>
                            <img src="./../../../assets/img/lan_nail.png" alt="">
                        </p>
                        <div class="_left_k_content">
                            <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                    maxlength="120"
                                    show-word-limit>
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            textarea:'',
            input:'',
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../../assets/css/student/B/Resourcesfigure.css';
</style>
<style>
._suff_div .el-input__inner{
    background: transparent;
    border: none;
    border-bottom: 1px solid #333;
    border-radius: 0;
    text-align: center;
}
._left_k_content .el-textarea,._top_ul_input .el-textarea{
    width: 100%;
    height: 100%;
}
._left_k_content .el-textarea__inner,._top_ul_input .el-textarea__inner{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    resize: none;
    padding: 5px 10px;
}
</style>